<template>
  <div class="pa-4">
    <NavBarHome @loadProducts="loadProducts($event)"/>

    <v-row class="pa-4">
      <v-col cols="12">
        <!-- cards -->
        <v-row>
          <v-container v-if="isLoading">
            <v-row v-for="row in 3" :key="row" class="products-row">
              <v-col v-for="col in 3" :key="col" class="mr-1" :cols="isMobile ? 12 : 4" :lg="4" :md="4" :sm="6"
                :xs="12">
                <v-skeleton-loader type="card" min-height="300px"></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-container>
          <ProductsCards v-show="!isLoading" v-for="product in products" :key="product.interno"
            :interno="product.interno" :denominacion="product.denominacion" :precio="product.precio"
            :stock="product.stock" :image="product.imagen"
            :articulosEquivalentes="product.articulosEquivalentes || []" />
        </v-row>

        <v-row v-if="products?.length == 0">
          <v-col cols="12" class="text-center pb-0">
            <h4>
              {{
                `No se encontraron artículos.`
              }}
            </h4>
          </v-col>
        </v-row>

        <!-- paginador -->
        <v-row justify="center" v-if="products?.length > 0">
          <v-col cols="8">
            <v-container v-if="!isMobile">
              <v-pagination v-model="page" :length="totalPages" active-color="primary"
                class="the-paginator"></v-pagination>
            </v-container>

            <!-- Paginador para móviles -->
            <div v-else class="mobile-pagination">
              <v-btn @click="goToPreviousPage" :disabled="page === 1" class="pagination-btn" icon>
                <v-icon size="20px">{{ leftArrowIcon }}</v-icon>
              </v-btn>
              <span class="pagination-text">
                {{ page }} de {{ totalPages }}
              </span>
              <v-btn @click="goToNextPage" :disabled="page === totalPages" class="pagination-btn" icon>
                <v-icon size="20px">{{ rightArrowIcon }}</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProductsCards from "@/components/UI/ProductsCards.vue";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import enums from "@/utils/enums";
import NavBarHome from "../Layout/NavBarHome.vue";

export default {
  name: "HomePage",
  components: {
    NavBarHome,
    ProductsCards,
  },
  data() {
    return {
      rules: rules,
      products: [],
      isLoading: false,
      page: 1,
      totalPages: 1,
      filters: {}, // Inicializa filters aquí
      leftArrowIcon: enums.icons.LEFT_ARROW,
      rightArrowIcon: enums.icons.RIGHT_ARROW,
    };
  },
  watch: {
    page: {
      handler() {
        if (this.filters && Object.keys(this.filters).length > 0) {
          const updatedPayload = {
            ...this.filters,
            page: this.page,
            equivalencias: true
          };
          this.loadProducts(updatedPayload);
        }
      },
      immediate: true,
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions({
      getProducts: "products/getProducts",
    }),
    async loadProducts(payload) {
      if (!payload) return;
      this.isLoading = true;
      this.filters = payload;
      const res = await this.getProducts(payload);
      this.products = res.articulos;
      this.totalPages = res.totalPages;
      this.isLoading = false;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    goToPreviousPage() {
      if (this.page > 1) {
        this.page -= 1;
      }
    },
    goToNextPage() {
      if (this.page < this.totalPages) {
        this.page += 1;
      }
    },
  },
};
</script>

<style scoped>
.v-btn-menu:hover {
  color: #1976d2;
}

.btn-row {
  justify-content: end;
  margin-right: 10px;
}

.menu-divider {
  min-height: 1250px;
  height: auto;
  border-right: 1px solid rgba(0, 0, 0, 0.6);
}

.products-row {
  flex-wrap: nowrap;
}

.mobile-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-btn {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.pagination-btn:disabled {
  color: #bdbdbd;
}

.pagination-text {
  margin: 0 15px;
  font-size: 1.2em;
}

::v-deep .v-pagination__item {
  box-shadow: none;
  border-radius: 20px;
}

::v-deep .v-pagination__navigation {
  box-shadow: none;
}
</style>
