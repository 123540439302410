<template>
  <v-col :cols="isMobile ? 12 : 4" :lg="4" :md="4" :sm="6" :xs="12">
    <v-card class="product-card">
      <div class="image-container">
        <v-img class="image" :src="image" alt="Product Image"> </v-img>
      </div>
      <v-card-title class="text-button pb-0 product-title" min-height="56px">
        {{ denominacion }}
      </v-card-title>
      <v-row no-gutters class="d-flex align-center pb-2">
        <v-col cols="10" class="pt-0">
          <v-card-subtitle class="text-button pb-0 pt-1 product-subtitle">
            Precio: ${{ formatPrice(precio) }}
          </v-card-subtitle>
          <v-card-subtitle
            class="text-button pb-0 pt-0 stock"
            :class="{ disponible: stock > 0, 'no-disponible': stock <= 0 }"
          >
            {{ stock > 0 ? "DISPONIBLE" : "NO DISPONIBLE" }}
          </v-card-subtitle>
        </v-col>
        <v-col cols="2" class="pt-0 text-right">
          <v-card-actions class="d-flex justify-end">
            <!-- Botón para ver equivalencias y abrir el diálogo -->
            <v-tooltip bottom v-if="articulosEquivalentes.length">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="openPopUp" icon>
                  <v-icon>{{ plusIcon }}</v-icon>
                </v-btn>
              </template>
              <span>Ver equivalencias</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="addedToCart ? 'green' : 'primary'"
                  v-bind="attrs"
                  v-on="on"
                  @click="toggleAddToCart"
                  icon
                  v-if="stock > 0"
                >
                  <v-icon v-if="!addedToCart">{{ carritoIcon }}</v-icon>
                  <v-icon v-else>{{ checkIcon }}</v-icon>
                </v-btn>
              </template>
              <span v-if="!addedToCart">Agregar al carrito</span>
              <span v-else>Producto agregado</span>
            </v-tooltip>

            <!-- Usar el componente ArticulosPopup y pasar los artículos -->
            <ArticulosPopup
              :articulos="articulosEquivalentes"
              ref="articulosPopup"
            />
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import ArticulosPopup from "./ArticulosPopUp.vue";
import enums from "@/utils/enums";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ArticulosPopup,
  },
  name: "ProductsCards",
  props: {
    interno: {
      type: String,
    },
    denominacion: {
      type: String,
    },
    precio: {
      type: Number,
    },
    stock: {
      type: Number,
    },
    image: {
      type: String,
    },
    articulosEquivalentes: {
      type: Array,
    },
  },
  data() {
    return {
      addedToCart: false,
      showDialog: false,
      carritoIcon: enums.icons.CARRITO,
      checkIcon: enums.icons.CHECK,
      plusIcon: enums.icons.SIGNO_MAS,
    };
  },
  mounted() {
    // Verificar si el producto ya está en el carrito al cargar el componente
    this.addedToCart = this.cartItems.some(
      (item) => item.interno === this.interno
    );
  },
  computed: {
    ...mapGetters("cart", ["cartItems"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions({
      updateCart: "cart/updateCart",
      removeCartItem: "cart/removeCartItem",
    }),
    formatPrice(price) {
      if (price == null) {
        price = 0; // Valor predeterminado
      }
      const formattedPrice = price
        .toFixed(2)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return formattedPrice;
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
      }
      return text;
    },
    toggleAddToCart() {
      this.addedToCart = !this.addedToCart;

      // Crea el objeto del producto para agregarlo al carrito
      const product = {
        [this.interno]: {
          interno: this.interno,
          denominacion: this.denominacion,
          precio: this.precio,
          image: this.image,
          cantidad: 1,
        },
      };

      // Despacha la acción para agregar o quitar del carrito
      if (this.addedToCart) {
        this.updateCart(product);
      } else {
        this.removeCartItem(this.articulo.interno);
      }
    },
    openPopUp() {
      this.$refs.articulosPopup.open(); // Abrir el popup desde el componente padre
    },
  },
};
</script>

<style scoped>
.product-card {
  width: 100%;
}

.image-container {
  height: 200px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.disponible {
  color: green !important;
}

.no-disponible {
  color: red !important;
}

.product-title {
  font-size: 16px !important;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.product-subtitle {
  font-size: 15px !important;
  line-height: 20px;
}

.stock {
  font-size: 12px !important;
  line-height: 20px;
}
</style>
